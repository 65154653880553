import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../api/Instance';
import moment from 'moment';

export const Detail = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const company_id = query.get('id');
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [thumbNailList, setThumbNailList] = useState([]);

  useEffect(() => {
    API.get('/company?id=' + company_id).then((res) => {
      console.log(res.data);
      setData(res.data);
      setCategories(res.data.categories);
      setSkillList(res.data.skillStack);
      setTagList(res.data.tagList);
      setThumbNailList(res.data.thumbNail);
      setUrlList(res.data.applyUrlList);
      console.log(res.data);
    });
  }, [company_id]);

  const toDate = (arr) => {
    if (typeof arr === Array) {
      // JavaScript의 Date는 0-based month이므로, month에 1을 빼줍니다.
      const [year, month, day, hour, minute, second, nano] = arr;
      return new Date(year, month - 1, day, hour, minute, second, nano / 1000000);
    }
  };

  const toBlobImage = (id) => {
    API.get('/image?id=' + id, {
      responseType: 'blob',
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        return res.data;
      }
    });

    return null;
  };

  return (
    <div className="container mx-auto max-w-screen-xl flex-grow">
      <div className="gallery flex flex-col">
        <figure className="m-2 flex flex-row overflow-x-auto">
          {thumbNailList.map((img) => (
            <img src={process.env.REACT_APP_API_BASE_URL + '/image?id=' + img.id} alt="thumbnail" width="500" height="300" />
          ))}
        </figure>
        <div className="info min-w-[500px] flex flex-col m-2">
          <p className="text-lg font-medium">이름 : {data.name}</p>
          <p className="text-lg font-medium">재목 : {data.title}</p>
          <p className="text-xl font-bold">직무 : {data.job}</p>
          <p>마감일 : {moment(toDate(data.deadLine)).format('YYYY-MM-DD HH:mm')}</p>
          <p>
            카테고리 :{' '}
            {categories.map((category, index) => (
              <span key={index}>{category}&ensp;</span>
            ))}
          </p>
          {/* <p>
            구분 :
            {tagList.map((tag, index) => (
              <span key={index}>{tag}&ensp;</span>
            ))}
          </p> */}
          <div>
            {urlList.map((url, index) => (
              <>
                <span>링크 : {url}</span>
              </>
            ))}
          </div>
        </div>
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-300"></hr>
      <article className="format format-sm sm:format-base lg:format-lg format-blue dark:format-invert flex-grow">
        <div dangerouslySetInnerHTML={{ __html: data.introduce }}></div>
      </article>
    </div>
  );
};
